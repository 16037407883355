<template lang="pug">
  include ../plugins/pug/btn

  div.servicespage.second__page
    div.custom__loader
    PageHeaderBlock(
      :header-title="'Services'"
      :header-content="`At Disoft, we offer a comprehensive range of services to empower businesses with innovative and reliable software solutions. Our services are designed to meet diverse needs across industries.`"
    )

    section.section
      div.container
        div.service__block
          div(
            v-for="(service, index) in services"
            :key="index"
          ).service__block-item.card
            div(
              @mouseenter="playLottie(index)"
              @mouseleave="playLottie(index)"
            ).service__block-body.card__body
              div.triangle
              div.service__block-title
                div.service__block-img
                  lottie-vue-player(
                    :ref="getLottieRef(index)"
                    :src="service.path"
                    :loop="true"
                  )
                h2(v-html="service.title").h2.uppercase.color-orange
              div.service__block-content.text
                p(
                  v-for="(paragraph, pIndex) in service.paragraphs"
                  :key="pIndex"
                ) {{ paragraph }}

    section.section
      div.container
        div.left__right
          div.left__right-img
            div.img__wrapp
              lottie-vue-player(
                :src="`https://lottie.host/f224189b-88bf-42d2-8031-2c20b3b84456/ph42E2qpvk.json`"
                :loop="true"
                :autoplay="true"
              )
          div.left__right-content
            h2.h2.left__right-title.uppercase what we <strong>do</strong>
            div.text-xl
              p Disoft expertise spans a wide range of cutting-edge technologies and services, enabling us to deliver comprehensive and innovative solutions for businesses across various industries.
              p Our clients range from startups to established enterprises across various industries, including finance, healthcare, e-commerce, education and technology. We take pride in our ability to understand and adapt to the unique requirements and challenges of each client, providing them with targeted software solutions that drive business growth, enhance operational efficiency and create a competitive edge in the market.
              p With our expert guidance in IT consulting and strategy, we help businesses navigate the complexities of digital transformation, optimize processes, and harness the power of technology to stay ahead of the curve.
</template>

<script>
export default {
  name: 'ServicesPage',
  components: {
    PageHeaderBlock: () => import('@/components/PageHeaderBlock')
  },
  data () {
    return {
      services: [
        {
          path: 'https://lottie.host/236b5adf-3402-46ec-8f5d-60fad3c2bda6/kgjRuUr2ux.json',
          title: 'Custom Software <strong>Development</strong>',
          paragraphs: [
            'We provide individual software solutions designed to address your unique business challenges. Our custom software development services ensure scalable and maintainable software architecture that perfectly aligns with your specific requirements.'
          ]
        },
        {
          path: 'https://lottie.host/ca795d79-e466-4332-8afa-6da83cf49e4a/bNTaHNB8Io.json',
          title: 'Mobile Application <strong>Development</strong>',
          paragraphs: [
            'Our team specializes in creating cutting-edge mobile applications for iOS and Android platforms. We leverage modern frameworks to develop engaging, feature-rich and cross-platform apps that enhance user experience and drive success.'
          ]
        },
        {
          path: 'https://lottie.host/01245187-1b65-4c3d-afa2-8653030a70d8/D5c8VSCBQA.json',
          title: 'Web Application <strong>Development</strong>',
          paragraphs: [
            'Disoft excel in building responsive, user-friendly and high-performance web applications. From e-commerce platforms and content management systems to enterprise-level solutions, we deliver powerful web apps сustomized to our clients business needs.'
          ]
        },
        {
          path: 'https://lottie.host/952d36e4-092b-47a2-a585-3b23d26015fa/dsS4qyKP26.json',
          title: 'UI/UX <br> <strong>Design</strong>',
          paragraphs: [
            'We create intuitive and engaging user interfaces using a user-centered design approach. Our UI/UX design services focus on enhancing user satisfaction and conversions, ensuring your digital products are visually appealing and easy to use.'
          ]
        },
        {
          path: 'https://lottie.host/9360bf5f-6153-4c08-82bc-33c2c2dd7f68/LEiYrCokTO.json',
          title: 'DevOps <br> <strong>Services</strong>',
          paragraphs: [
            'Our DevOps services streamline the development, deployment and management of software solutions. We adopt agile methodologies and best practices to enhance collaboration, increase efficiency, and ensure seamless delivery of high-quality software.'
          ]
        },
        {
          path: 'https://lottie.host/aceb29ab-3af3-41e4-a77d-f6b9188ffda9/dVAVKXygcz.json',
          title: 'IT Consulting and <strong>Strategy</strong>',
          paragraphs: [
            'We offer expert IT consulting and strategy services to help you navigate the complexities of digital transformation. Our team provides technology roadmaps, process optimization and technology selection guidance, empowering your business to thrive in the digital age.'
          ]
        }
      ]
    }
  },
  methods: {
    getLottieRef (index) {
      return 'lottieRef' + index
    },
    playLottie (index) {
      let lottieIndex = 'lottieRef' + index
      this.$refs[lottieIndex][0].togglePlayPause()
    }
  }
}
</script>
